<template>
  <div>
    <h1 class="main-header" v-translate translate-context="form'">Create</h1>
    <Form :data=empty @save="onSave" :save_text="$pgettext('form','Create')"/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Form from "@/domain/region/region/Form.vue";
import {SuccessNotification} from "@/domain/core/exception/exceptions";

export default {
  name: 'NewRegion',
  components: {Form},
  data() {
    return {
      empty: {
        name: {},
        code: '',
        currency: '',
        countries: [],
        is_public: false,
      }
    }
  },
  methods: {
    ...mapActions('region', [
      'createRegion',
    ]),
    async onSave(data) {
      await this.createRegion(data)
      await this.$router.push({name: 'region.list'})
      throw new SuccessNotification(this.$pgettext('region', 'Region created!'))
    }
  }
}
</script>